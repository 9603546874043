<template>
      <div class="VersionSetting">


        <div class="VersionSetting__published-container" @click.stop="selectVersion(published)">
          <div class="VersionSetting__section-title">
            <span class="t">Published Version: </span>
          </div>
          <div :class="`VersionSetting__version_item ${isSelected(published) && 'VersionSetting__version_item__selected'}`">
            <div v-if="!(edit && edit.type === 'published')">
              <div class="version_title">
                <div class="name" v-if="published.name">
                  <span>{{ published.name }}</span>
                </div>
                <div class="version_created-by">
                  <span>{{ formatDate(published.createdAt) }} </span>
                  <span> by {{ getCreatedBy(published.createdBy) }} </span>
                </div>
              </div>
              <div class="version_title" v-if="published.description">
                <span>{{ published.description }}</span>
              </div>
              <div class="actions" v-if="!selectVersionLoading">
                <v-btn icon @click.stop="editVersion({ version: published, type: 'published' })" class="VersionSetting_icon-btn" title="Edit Version Description and Name">
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </div>
              <div class="actions" v-if="selectVersionLoading">
                <v-progress-circular
                    :size="25"
                    :width="2"
                    style="margin-top: 6px; margin-right: 6px"
                    color="primary"
                    indeterminate
                />
              </div>
            </div>

            <div class="VersionSettings_edit-form" v-if="edit && edit.type === 'published'">
              <div class="name-input">
                <v-text-field label="Name" outlined color="primary" dense v-model="edit.name"></v-text-field>
              </div>
              <div class="description-input">
                <v-text-field label="Description" outlined color="primary" dense v-model="edit.description"></v-text-field>
              </div>
              <div>
                <v-btn text @click="saveEdit()" type="primary"> Save </v-btn>
                <v-btn text @click="edit = null"> Cancel </v-btn>
              </div>
            </div>
          </div>
        </div>

      <div class="VersionSetting__section-title">
        <span class="t">All Versions: </span>
      </div>
      <div class="VersionSetting__search">
        <v-text-field
            label="Search"
            outlined
            color="primary"
            dense
            v-on:input="debounceInput"
            v-model="search"
        ></v-text-field>
        <div v-if="workflowVersionsLoading" style="padding: 5px">
          <v-progress-circular
              :size="25"
              :width="2"
              color="primary"
              indeterminate
          />
        </div>
      </div>

      <div v-if="!workflowVersionsLoading" class="VersionSetting__versions-list-container">
        <div :class="`VersionSetting__version_item ${isSelected(item) && 'VersionSetting__version_item__selected'}`" v-for="(item, index) in versions" :key="item.id" @click.stop="selectVersion(item)">
          <div v-if="!(edit && edit.type === 'list' && edit.id === item.id)">
          <div class="version_title">
            <div class="name" v-if="item.name">
              <span>{{ item.name }}</span>
            </div>
            <div class="version_created-by">
              <span>{{ formatDate(item.createdAt) }}</span>
              <span> by {{ getCreatedBy(item.createdBy) }} </span>
            </div>
          </div>
          <div class="version_description" v-if="item.description">
            <span>{{ item.description }}</span>
          </div>
          <div class="actions" v-if="!selectVersionLoading">
            <template v-if="runVersion">
              <v-btn icon @click.stop="handlerRunSelectedVersion(item)" class="VersionSetting_icon-btn" title="Run selected version">
                <v-icon> mdi-bug-check </v-icon>
              </v-btn>
            </template>
            <template v-else-if="!overwriteVersion">
              <v-btn icon @click.stop="publishVersions(item)" class="VersionSetting_icon-btn"  :disabled="deleteVersionLoading" title="Publish Workflow Version" v-if="!isPublished(item)">
                <v-icon> mdi-publish </v-icon>
              </v-btn>
              <v-btn icon @click.stop="editVersion({ version: item, type: 'list' })" class="VersionSetting_icon-btn" :disabled="deleteVersionLoading" title="Edit Version Description and Name">
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
              <v-btn icon @click.stop="handleDeleteVersion(item)" :disabled="deleteVersionLoading" class="VersionSetting_icon-btn" title="Delete Version" v-if="!isPublished(item) && !isSelected(item)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <v-btn v-else icon @click.stop="handleSelectOverwrite(item)" title="Overwrite Version">
              <v-icon> mdi-file-edit </v-icon>
            </v-btn>
            
          </div>

            <div class="actions" v-if="selectVersionLoading">
              <v-progress-circular
                  :size="25"
                  :width="2"
                  style="margin-top: 6px; margin-right: 6px"
                  color="primary"
                  indeterminate
              />
            </div>

          </div>

          <div class="VersionSetting__edit_form" v-if="edit && edit.type === 'list' && edit.id === item.id">
            <div class="name-input">
              <v-text-field label="Name" background-color="white" outlined color="primary" dense v-model="edit.name"></v-text-field>
            </div>
            <div class="description-input">
              <v-text-field label="Description" background-color="white" outlined color="primary" dense v-model="edit.description"></v-text-field>
            </div>
            <div>
              <v-btn style="background-color: white; margin-right: 10px" text @click="saveEdit(index)" type="primary"> Save </v-btn>
              <v-btn style="background-color: white" text @click="edit = null"> Cancel </v-btn>
            </div>
          </div>

          <div class="VersionSetting__commitList">
            <v-btn icon @click.stop="showCommits(item)" class="VersionSetting__icon-btn" title="Show commits">
              <v-icon> {{ commitsForVersion?.id === item?.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
            </v-btn>
          </div>

          <div v-if="commitsForVersion && commitsForVersion?.id === item?.id">
            <div :class="`VersionSetting__commits ${isSelectedCommit(commit) && 'VersionSetting__commits__selected'}`" v-for="(commit) in item.commits" :key="commit.id"  @click.stop="selectCommit(item, commit)">
              <div>
                <div class="commit_message">
                  <div class="message" v-if="commit.message">
                    <span>{{ commit.message }}</span>
                  </div>
                  <div class="commit_created-at">
                    <span>{{ formatDate(commit.createdAt) }}</span>
                  </div>
                </div>
                <div class="actions">
                  <template>
                    <v-btn icon @click.stop="handleRestoreCommit(commit, item)" title="Restore Commit" :loading="restoreCommitLoading">
                      <v-icon> mdi-restore </v-icon>
                    </v-btn>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
</template>

<script>
import * as moment from "moment-timezone";
import debounce from "lodash/debounce";
import {createNamespacedHelpers} from "vuex";

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
  mapMutations: workflowDetailsMutations,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'VersionSetting',
  components: {},
  props: {},
  computed: {
    ...workflowDetailsGetters({
      workflow: 'WORKFLOW',
      versions: 'WORKFLOW_VERSIONS',
      published: 'WORKFLOW_PUBLISHED_VERSION',
      selected: 'WORKFLOW_SELECTED_VERSION',
      selectedCommit: 'WORKFLOW_SELECTED_COMMIT',
      workflowVersionsLoading: 'WORKFLOW_VERSIONS_LOADING',
      publishVersionLoading: 'PUBLISH_WORKFLOW_VERSION_LOADING',
      selectVersionLoading: 'SELECT_WORKFLOW_VERSION_LOADING',
      updateVersionLoading: 'UPDATE_WORKFLOW_VERSION_LOADING',
      deleteVersionLoading: 'DELETE_WORKFLOW_VERSION_LOADING',
      overwriteVersion:'SET_OPEN_VERSION_MODAL_FROM_OVERWRITE',
      runVersion:'SET_OPEN_VERSION_MODAL_FROM_RUN',
      restoreCommitLoading: 'RESTORE_COMMIT_LOADING'
    }),
  },
  data() {
    return {
      localTZ: 'UTC',
      search: '',
      edit: null,
      commitsForVersion: null,
    };
  },
  methods: {
    ...workflowDetailsActions(['publishWorkflowVersion','overwriteWorkflow','setWorkflowVersion', 'updateWorkflowVersion', 'getWorkflowVersions','runWorkflow','deleteWorkflowVersion', 'restoreCommit']),
    ...workflowDetailsMutations({
      setPublished: 'SET_WORKFLOW_PUBLISHED_VERSION',
      setSelected: 'SET_WORKFLOW_SELECT_VERSION',
      setVersions: 'SET_WORKFLOW_VERSIONS',
      openVersionModalFromRun:'SET_OPEN_VERSION_MODAL_FROM_RUN'
    }),
    isSelected(version) {
      return this.selected.id === version.id;
    },
    isSelectedCommit(commit) {
      return this.selectedCommit?.id === commit.id;
    },
    async handlerRunSelectedVersion(item,production = false){
     await this.selectVersion(item);
     await this.runWorkflow({ isProduction: production, isNew: this.isNew ,isSelectedVersionRun:true});
      if(this.openVersionModalFromRun){
        this.openVersionModalFromRun(false);
      }
    },
    handleSelectOverwrite(versionItem) {
      this.overwriteWorkflow({versionItem});
    },
   async  handleDeleteVersion(versionItem) {
      await this.deleteWorkflowVersion({versionItem});
      await this.getWorkflowVersions({ workflow_id: this.workflow.id, search: this.search });
    },
    handleRestoreCommit(commit, version) {
        this.restoreCommit({
          workflow_id: this.workflow.id,
          version_id: version.id,
          commit_id: commit.id,
        })
    },
    isPublished(version) {
      return this.published.id === version.id;
    },
    showCommits(version) {
      if(this.commitsForVersion?.id === version?.id) {
        this.commitsForVersion = null;
        return;
      }
      this.commitsForVersion = version
    },
    formatDate(date) {
      return moment(date).tz(this.localTZ).format("YYYY-MM-DD, HH:mm:SS");
    },
    setLocalTimezone() {
      this.localTZ = moment.tz.guess();
    },
    getCreatedBy(createdBy) {
      if(!createdBy) {
        return ``;
      }
      if(!createdBy.firstName && !createdBy.lastName) {
        return createdBy.email;
      }
      return `${createdBy.firstName} ${createdBy.lastName} (${createdBy.email})`
    },
    editVersion(edit) {
      this.edit = {
        ...edit.version,
        type: edit.type,
      };
    },
    async saveEdit(index) {
      if(!this.edit?.id) {
        return;
      }
      const version = await this.updateWorkflowVersion({
        version_id: this.edit.id,
        workflow_id: this.workflow.id,
        name: this.edit.name,
        description: this.edit.description,
      })

      if(this.edit.type === 'published' || this.edit.id === this.published.id) {
        this.setPublished({...this.selected, name: version.name, description: version.description })
      }

      if(this.edit.type === 'list') {
        if(typeof index !== 'undefined') {
          const versions = [...this.versions];
          const prev = versions[index];
          versions[index] = { ...prev, name: version.name, description: version.description  };
          this.setVersions([...versions])
        }
      } else {
        const fIndex = this.versions.findIndex((item) => item.id === version.id);
        if(fIndex > -1) {
          const versions = [...this.versions];
          versions[fIndex] = version;
          this.setVersions([...versions])
        }
      }

      if(this.edit.type === 'selected' || this.edit.id === this.selected.id) {
        this.setSelected({...this.selected, name: version.name, description: version.description })
      }

      this.edit = null;
    },
    selectVersion(version) {
      if(this.selected?.id === version?.id) {
        return;
      }
      this.setWorkflowVersion({
        workflow_id: this.workflow.id,
        version_id: version.id,
      })
    },
    selectCommit(version, commit) {
      if(this.selectedCommit?.id === commit.id) {
        return;
      }
      this.setWorkflowVersion({
        workflow_id: this.workflow.id,
        version_id: version.id,
        commit_id: commit.id,
      })
    },
    publishVersions(version) {
      this.publishWorkflowVersion({
        workflow_id: this.workflow.id,
        version_id: version.id,
      })
    },
    debounceInput: debounce(function (e) {
      this.search = e;
      this.getWorkflowVersions({ workflow_id: this.workflow.id, search: this.search });
    }, 700)
  },
  destroyed() {
    this.setVersions([]);
  },
};
</script>

<style lang="scss" scoped>
@import './version-setting';
</style>
