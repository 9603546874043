<template>
  <div class="AutomationSidebar">
    <v-alert
      dense
      type="warning"
      v-if="workflowEdited"
      rounded="0"
      class="AutomationSidebar__workflow-edited-alert"
    >
      You have unsaved changes on this workflow
    </v-alert>

    <test-block />
    <StartSettings
        v-if="!(running.validation?.validation_status === workflowStatus.SUCCESS &&
        (running.vm_status === workflowStatus.SUCCESS ||
          running.vm_status === workflowStatus.SKIPPED))"
    />

    <settings-block />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { actionsTypes } from '@/util/action-types';
import cloneDeep from 'lodash/cloneDeep';
import StartSettings from '@/components/connector-settings/start-settings/start-settings.vue';
import SettingsBlock from './settings-block/settings-block';
import TestBlock from './test-block/test-block';
import {workflowStatus} from "@/util/workflow-statuses";

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'AutomationSidebar',
  components: {
    StartSettings,
    SettingsBlock,
    TestBlock,
  },
  data() {
    return {
      workflowStatus: workflowStatus,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    ...workflowDetailsGetters({
      startSettings: 'START_SETTINGS',
      workflow: 'WORKFLOW',
      workflowEdited: 'WORKFLOW_EDITED',
      workflowRunning: 'WORKFLOW_RUNNING',
      movingNodeId: 'MOVING_NODE_ID',
      runStatus: 'WORKFLOW_RUNNING_OBJ',
      workflowStarted: 'WORKFLOW_STARTED',
      checkingWorkflowStatus: 'CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING',
    }),
    actionsTypes() {
      const name = this.customer.CustomersFeature.data_source_name;
      const at = actionsTypes(name);
      const trigger_type = this.startSettings.trigger_type;

      return cloneDeep(at).filter((action) => {
        return !(
          action.action_type === 'human_in_the_loop' &&
          trigger_type &&
          trigger_type !== 'manual'
        );
      });
    },
    automationId() {
      return this.$route.params.automationId;
    },
    running() {
      if (this.automationId) {
        return this.runStatus(this.automationId);
      }
      return {};
    },
    isWorkflowRunning() {
      return (
          this.workflowRunning ||
          this.workflowStarted ||
          this.checkingWorkflowStatus ||
          this.running.workflow_status === 'PENDING'
      );
    },
  },
  watch: {
    workflowRunning(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.tab = 1;
      }
    },
  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
// @import "@/sass/variables.scss";
@import './automation-sidebar';
</style>
